<template>
  <v-layout class="rounded rounded-md">
    <!-- TODO: Account Icon + Name // XP + Nummer //  -->
    <v-app-bar :title="username + '  //  '+ points + ' XP  //  '+ coins + ' Coins' " color="primary"></v-app-bar>
    <!-- Styles über Klassen   -->
    <v-main class="ifap-nimbus d-flex align-center justify-center">
      <router-view @reloadPoints="reload"/>
    </v-main>

    <v-bottom-navigation>

      <v-btn to="profil">
        <v-icon>mdi-account</v-icon>
        <span>Profil</span>
      </v-btn>

      <v-btn to="getpoints">
        <v-icon>mdi-plus-circle-outline</v-icon>
        <span>Punkte</span>
      </v-btn>

      <v-btn to="voyage">
        <v-icon>mdi-rocket</v-icon>
        <span>Reise</span>
      </v-btn>

      <v-btn to="rewards">
        <v-icon>mdi-shopping</v-icon>
        <span>Shop</span>
      </v-btn>

      <v-btn to="rules">
        <v-icon>mdi-information-slab-circle</v-icon>
        <span>Info</span>
      </v-btn>

   </v-bottom-navigation>
  </v-layout>
</template>

<!-- Javascript -->
<script>

export default {
  name: 'App',
  data( ) {
    return {
      username: "",
      points: 0, 
      coins: 0,
      usedcodes: [],
    }
  },
  emits:[
    "reloadPoints"
  ],
  methods: {
    // Code überprüfen und Punkte erhalten 
    reload(){
      this.username = localStorage.getItem("username")?? "Gamifap";
      this.points = localStorage.getItem("points")?? "0";
      this.coins = localStorage.getItem("coins")?? "0"; 
      }
  },
  mounted() {
    this.reload();
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

h1 {
  color: black;
  font-size: 28px;
  padding: 21px 0px 26px 0px;
}

.ifap-nimbus{
  max-width: 480px;
  /* padding: 30px; */
  width: 100%;
  margin: 0 auto;
}

</style>
