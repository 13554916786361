import {createRouter, createWebHashHistory} from "vue-router";

const routes = [
	{
		path: '/',
		name: 'start',
		component: () => import('../pages/StartPage.vue')
	},
	{
		path: '/profil',
		name: 'profil',
		component: () => import('../pages/MyProfil.vue')
	},
	{
		path: '/userregister',
		name: 'userregister',
		component: () => import('../pages/UserRegister.vue')
	},
	{
		path: '/getpoints',
		name: 'getpoints',
		component: () => import('../pages/GetPoints.vue')
	},
	{
		path: '/voyage',
		name: 'voyage',
		component: () => import('../pages/MyVoyage.vue')
	},
	{
		path: '/rewards',
		name: 'rewards',
		component: () => import('../pages/GetRewards.vue')
	},
	{
		path: '/rules',
		name: 'rules',
		component: () => import('../pages/TheRules.vue')
	},
	{
		path: '/impressum',
		name: 'impressum',
		component: () => import('../pages/TheImpressum.vue')
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
	scrollBehavior(){
		// scroll to top
		return{ top:0}

	}
});

export default router;
