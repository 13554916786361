import { createVuetify } from "vuetify";

import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

export default createVuetify({
	components,
	directives,
	theme: {
		themes: {
			light: {
				colors: {
					"primary": "#123456",
					"secondary": "#D10000",
					"success": "#009864",
					"info": "#F1F5FF",
					"background": "#FFFFFF",
					"background-darken-1": "#666666",
					"background-darken-2": "#292929",
					"background-lighten-1": "#D1D1D1",
				}
			}
		}
	}
});
